import React from "react";
import { Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { AntdIcon } from "../AntdIcon";
import { ThemeContext } from "../../providers";
import { useOnClickOutside } from "../../hooks";
import {
  AppDispatch
  , RootState, useAppDispatch
} from "../../app/store";
import { markAsRead, removeNotification, setExpand } from "../../features/notification";
import { useSelector } from "react-redux";

export const Notification = ({
  iconname,
  onActionClick,
}: {
  iconname: string;
  onActionClick: (action) => void;
}) => {
  const { theme } = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  const dispatch: AppDispatch = useAppDispatch();
  useOnClickOutside(ref, () => setExpanded(false));

  const notificationList = useSelector(
    (state: RootState) => state.notification.list
  );

  const unreadCount = useSelector(
    (state: RootState) => state.notification.unreadCount
  );

  const handleMarkAsRead = (id: string) => dispatch(markAsRead(id));

  useEffect(() => {
    let intervalId: any = -1;
    if (notificationList && notificationList.length > 0) {
      intervalId = setInterval(() => {
        const now = Date.now();
        const ids = notificationList
          .filter((item) => {
            const ttl = parseInt(item.data.ttl)
            if (ttl > 0) {
              if ((+item.data.date_time * 1000) + ttl * 1000 < now) {
                return true;
              }
            }
            return false;
          }).map(item => item.data.id)
        ids.map(id => dispatch(removeNotification(id)));
      }, 60000);
    }

    return () => clearInterval(intervalId);
  }, [notificationList.length])

  if (notificationList.length === 0) return <></>;

  const Dropdown = ({ item }: { item: any }) => {
    const [actions, setActions] = useState([]);

    useEffect(() => {
      if (item.data && item.data.meta_data) {
        const meta_data = JSON.parse(item.data.meta_data);
        setActions(meta_data.actions);
      }
    }, [item.data]);

    return (
      <div className="flex justify-between" onClick={(e) => {
        dispatch(setExpand(item.id))
        handleMarkAsRead(item.id)
      }}>
        <div className="pl-[5px] flex-grow">
          <div
            className="w-full  h-[50px] px-2 flex items-center justify-left overflow-hidden justify-between"
          >
            <img src={require('@twin-shared/lib/assets/icons/twin.png')} width={28} height={28} className="rounded-full" />

            <div className="w-full ml-1 cursor-auto">
              <div className={`line-clamp-1 ${theme.isDark ? "text-white" : "text-black"}`}>{item.data?.title}</div>
              {!item.expand && <div className={`text-sm  line-clamp-1 ${theme.isDark ? "text-gray-400" : "text-gray-500"}`}>{item.data?.body}</div>}
            </div>
            <span
              onClick={() => {
                item.expand && dispatch(removeNotification(item.id));
              }}
              className="h-full cursor-pointer"
            >
              <AntdIcon type={item.expand ? "CloseOutlined" : "DownOutlined"} className={`text-xs !text-black`} />
            </span>
          </div>
          <Collapse isOpened={item.expand}>
            <div className="px-3 pb-2">
              <div className="text-sm text-gray-500 mb-3 text-justify">
                {item.data?.body}
              </div>
              <div className="flex justify-center flex-col gap-2">
                {actions.map(action => (
                  <div className={`flex justify-center gap-3 ${action?.label_icon_position === "left" ? "flex-row-reverse" : "flex-row"}`}>
                    <button
                      onClick={() => {
                        onActionClick && onActionClick(action);
                        dispatch(removeNotification(item.id));
                      }}
                      style={{
                        backgroundColor: theme.colors.primary,
                        color: theme.colors.textPrimary,
                      }}
                      className="font-bold py-1 px-4 rounded w-full"
                    >
                      {action.label}
                    </button>
                    <img src={require(`@twin-shared/lib/assets/icons/${action?.label_icon}.png`)} className="w-7 h-7 rounded-full" />
                  </div>
                ))}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`fixed top-1 lg:right-[27%] md:right-[10%] right-[15%] z-[1]`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          setExpanded(true);
        }}
        className="cursor-pointer text-white p-[2px] bg-gray-500 rounded-[5px]"
        style={{
          backgroundColor: "transparent",
        }}
      >
        {unreadCount > 0 && (
          <span
            className={`absolute flex h-3 right-1 ${unreadCount > 9 ? "w-5" : "w-3"}`}
          >
            <span
              style={{ fontSize: "9px" }}
              className={`relative p-2 inline-flex rounded-full h-3 bg-red-500 items-center justify-center ${unreadCount > 9 ? "w-5" : "w-3"}`}
            >
              {unreadCount < 100 ? unreadCount : "99+"}
            </span>
          </span>
        )}

        <AntdIcon type={iconname} className="text-[30px] text-gray-300" />
      </div>
      <div className="relative" ref={ref}>
        <div
          style={{ scrollbarWidth: "none" }}
          className={`w-fit absolute top-1 lg:right-HORIZONTAL_MARGIN md:right-[10%] right-[7%] overflow-auto overflow-y pr-3`}
        >
          <div
            className={`${expanded && "max-h-[85vh]"}`}
            style={{ minWidth: 300 }}
          >
            <Typography
              component="div"
              sx={{
                whiteSpace: "pre-wrap",
                fontSize: "1rem",
                color: "black",
                "@media (max-width: 600px)": {
                  fontSize: "1rem",
                },
                "@media (max-width: 400px)": {
                  fontSize: "0.8rem",
                },
                "@media (max-width: 300px)": {
                  fontSize: "0.7rem",
                },
                "@media (max-width: 200px)": {
                  fontSize: "0.6rem",
                },
              }}
            >
              <Collapse isOpened={expanded}>
                <div className="bg-transparent">
                  {notificationList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`mb-2 gap-1 rounded-2xl border ${theme.isDark ?? "border-black"} `}
                        style={{ backgroundColor: theme.colors.tertiary }}
                      >
                        <Dropdown item={item} />
                      </div>
                    );
                  })}
                </div>
              </Collapse>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
