import { createSlice } from "@reduxjs/toolkit";

export interface NotificationState {
  unreadCount: number;
  list: any[];
  clickIds: string[];
}

const initialState: NotificationState = {
  unreadCount: 0,
  list: [],
  clickIds: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      if (!state.clickIds.includes(action.payload.id)) {
        state.list = [...state.list, action.payload];
        state.unreadCount = state.list.filter((a) => !a.markAsRead).length;
      } else {
        state.clickIds = state.clickIds.filter(
          (id) => id !== action.payload.id
        );
      }
    },
    markAsRead: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload) {
          item.markAsRead = true;
        }
        return item;
      });
      state.unreadCount = state.list.filter((item) => !item.markAsRead).length;
    },
    setExpand: (state, action) => {
      state.list = state.list.map((item) => {
        if (item.id === action.payload) {
          item.expand = !item.expand;
        }
        return item;
      });
    },
    removeNotification: (state, action) => {
      let markAsRead = false;
      state.list = state.list.filter((msg) => {
        if (msg.id !== action.payload) {
          return true;
        } else {
          markAsRead = msg.markAsRead;
          return false;
        }
      });
      state.unreadCount = markAsRead
        ? state.unreadCount
        : state.unreadCount - 1;
    },
    addClickId: (state, action) => {
      state.clickIds = [...(state.clickIds ?? []), action.payload];
    },
    updateClickIds: (state, action) => {
      state.clickIds = state.clickIds.filter(
        (id) => !action.payload.includes(id)
      );
    },
  },
});

export const {
  addNotification,
  markAsRead,
  removeNotification,
  setExpand,
  addClickId,
  updateClickIds,
} = notificationSlice.actions;
export default notificationSlice.reducer;
