import { useContext, useState } from "react";
import { ThemeContext } from "../../providers";
import { Button } from "antd";

const styleClass = "even:bg-white odd:bg-gray-300";
// const styleClass = '';
export const List = ({
  component,
  render,
}: {
  component: any;
  render: (any) => any;
}) => {
  const swapcolor = component?.swapcolor;
  const { theme } = useContext(ThemeContext);
  const { batch_size: batchSize ,children} = component;
  const data = children || [];
  
  const [visibleCount, setVisibleCount] = useState(batchSize);
  const visibleData = visibleCount && visibleCount > 1 ?   data.slice(0, visibleCount) : data;

  const canShowMore = visibleCount < data.length;
  const canShowLess = visibleCount > batchSize;

  const handleShowMore = () => {
    setVisibleCount((prevCount) =>
      Math.min(prevCount + batchSize, data.length)
    );
  };

  const handleShowLess = () => {
    setVisibleCount((prevCount) => Math.max(prevCount - batchSize, batchSize));
  };


  const renderButtons = () => (
    <div style={{ flexDirection: "row" }}>
      <Button
        disabled={!canShowMore}
        onClick={handleShowMore}
        className="rounded-[5px] max-[28px] h-[27px]"
        style={{
          backgroundColor: 'white',
          color: 'black',
          opacity: canShowMore ?  1: 0.6, 
        }}
      >
        Show More
      </Button>
      <Button
        disabled={!canShowLess}
        onClick={handleShowLess}
        className="rounded-[5px] max-[28px] h-[27px] m-[5px]"
        style={{
          backgroundColor: 'white',
          color: 'black',
          opacity: canShowLess ?  1:0.6,
         }}
      >
        Show Less
      </Button>
    </div>
  );
  return (
    <div style={{ ...component.ui_args?.web }}>
      {visibleData.map((child, index) => {
        let background = { backgroundColor: "transparent" };
        if (swapcolor)
          background =
            index % 2 == 0
              ? { backgroundColor: theme.isDark ? "#444c48" : "#d1d5db" }
              : { backgroundColor: theme.colors.background };

        return (
          <div key={index} style={{ ...background }}>
            {render(child)}
          </div>
        );
      })}
       {batchSize && batchSize > 1 && data.length > batchSize  ?  renderButtons() :<div/>} 
    </div>
  );
};
