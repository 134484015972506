import React, { useRef, useState, useEffect, useContext } from "react";
import Webcam from "react-webcam";
import { Button } from "antd";

import { Box, IconButton } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SwitchCameraIcon from "@mui/icons-material/SwitchCamera";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { ReactComponent as UploadFileIcon } from "@twin-shared/lib/assets/uploadfileicon.svg";
import { ThemeContext } from "../../providers";
import "./styles.css";

interface PhotoInputProps {
  data: any;
  component: any;
  args: any;
  onChange: (component: any, value: any) => void;
}

export const TakePhotoInput = (props: PhotoInputProps) => {
  const webcamRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [isWebcamOpen, setIsWebcamOpen] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(false);
  const [hasMultipleCameras, setHasMultipleCameras] = useState(false);
  const { theme } = useContext(ThemeContext);

  const { data, component, onChange, args, ...rest } = props;
  const [files, setFiles] = useState(data[component.id] ?? []);

  useEffect(() => {
    if (files.length > 0) {
      onChange(component, files);
    }
  }, [files]);

  useEffect(() => {
    if (!hasMultipleCameras) {
      setIsFrontCamera(true);
    }else{
      setIsFrontCamera(false);
    }
  }, [hasMultipleCameras]);

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: "image/jpeg" });
  };

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageData(imageSrc);
  };

  const handleDone = async () => {
    if (imageData) {
      setImageData(null); // Clear after submission
      setIsWebcamOpen(false); // Close webcam

      const file: File = dataURLtoFile(imageData, "captured_photo.jpg");
      const arrayBuffer = await file.arrayBuffer();
      setFiles([
        {
          content: arrayBuffer,
          url: imageData,
          metadata: {
            name: "captured_photo.jpg",
            type: "image/jpeg",
            size: file.size,
            uid: 12345,
            success: true,
          },
        },
      ]);
    }
  };

  const handleCancel = () => {
    setImageData(null);
    setIsWebcamOpen(false); // Close webcam
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  const checkCameras = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputs = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setHasMultipleCameras(videoInputs.length > 1);
    } catch (error) {
      console.error("Error enumerating devices:", error);
    }
  };

  const handleUserMedia = () => {
    checkCameras();
  };

  useEffect(() => {
    if (isWebcamOpen) {
      checkCameras(); // Only enumerate devices after webcam is opened
    }
  }, [isWebcamOpen]);

  const renderCameraBtn = () => {
    return (
      <Button
        className="camera-icon-btn"
        style={{
          backgroundColor: theme.colors.primary,
          transition: "none",
        }}
        onClick={() => {
          setIsWebcamOpen(true);
        }}>
        <CameraAltIcon
          fontSize="medium"
          sx={{ fontSize: 28, color: "white" }}
        />
      </Button>
    );
  };

  const onRemovePhoto = () => {
    setFiles([]);
    onChange(component, []);
  };

  const renderCamView = () => {
    return (
      <div className="webcam-container">
        <div className="webcam-relative">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            mirrored={isFrontCamera}
            videoConstraints={{
              facingMode: isFrontCamera ? "user" : "environment",
             }}
            className="webcam-live"
            style={{borderRadius: '10px'}}
            onUserMedia={handleUserMedia}
          />

          
        </div>

        <div className="cap-button-row"  >
          <div className="cap-row-button" />
          <div className="cap-row-button">
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}>
              <img src={require("../../assets/images/camera_capture.png")}
                style={{ width: '60px', height: '60px', objectFit: 'fill' }}
                onClick={capture}
              />
            </div>
          </div>

          <div className="cap-row-button" >
            {hasMultipleCameras && ( 

              <div
                onClick={toggleCamera}
                className="flipCameraContainer"
              >
                <img
                  src={require("../../assets/images/rotation.png")}
                  alt="switch camera"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: 'contain'
                  }}
                
                />
              </div>
             )} 
          </div>
        </div>
      </div>
    );
  };

  const renderThumbnail = () => {
    return (
      <div className="webcam-thumbnail">
        <img  src={files[0].url} />
        <IconButton
          className="delete-btn"
          style={{
            transition: "none",
          }}
          onClick={onRemovePhoto}>
          <DeleteForeverIcon />
        </IconButton>
      </div>
    );
  };

  if (files && files.length) {
    return renderThumbnail();
  }
  return (
    <Box flexDirection="column">
      {!isWebcamOpen && !imageData ? (
        renderCameraBtn()
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          {!imageData ? (
            renderCamView()
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src={imageData}
                alt="Captured"
                className="img-capture-view"
              />
              <Box className="action-buttons">
                <IconButton color="success" onClick={handleDone}>
                  <CheckCircleOutlineIcon fontSize="large" />
                </IconButton>
                <IconButton color="error" onClick={handleCancel}>
                  <CancelIcon fontSize="large" />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
