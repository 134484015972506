import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useSpring } from "react-spring";
import Lottie from "react-lottie";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { AppMode, hexToRgba } from "@twin-shared";
import waveAnimation from "../../assets/animations/wave.json";
import MicIcon from "@mui/icons-material/Mic";
import { TwinAppEvent } from "@twin-shared";
import { useSocketContext } from "../../providers";
import { ThemeContext } from "../../providers/ThemeProvider";
import { AppDispatch, RootState, useAppDispatch } from "../../app/store";
import { setTextChat } from "./chatSlice";
import { PulseCircle } from "../../components";
import { useRecording } from "../../services";
import { useAppContext } from "../../providers/AppProvider";
import { useSelector } from "react-redux";
import { setActiveVideoId, setMimiIsTyping } from "../message/messageSlice";
import "./styles.css";

export const ChatBox = () => {
  const { theme } = useContext(ThemeContext);
  const { isOnline, sendMessage, reconnect } = useSocketContext();
  const { setIsChatBoxFocused } = useAppContext();
  const [seconds, setSeconds] = useState(0);
  const [mode, setMode] = useState<AppMode>(AppMode.text);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [messageLabel, setMessageLabel] = useState<string | null>(null);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const dispatch: AppDispatch = useAppDispatch();
  const { audioRecordingStart, audioRecordingStop } = useRecording(
    audioRecorder,
    "voice_transcript"
  );
  const { mimiIsTyping } = useSelector((state: RootState) => state.message);

 
  useEffect(() => {
    dispatch(setActiveVideoId(undefined));
  }, []);
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: waveAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [scaleAnimation, setScaleAnimation] = useSpring(() => ({
    transform: "scale(1)",
    config: { friction: 3 },
  }));

  const [widthAnimation, setWidthAnimation] = useSpring(() => ({
    width: "94%",
    config: { duration: 200 },
  }));

  useEffect(() => {
    setTimeout(() => setShowTooltip(false), 5000);
  }, []);

  useEffect(() => {
    let interval;
    if (timerRunning) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!timerRunning && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerRunning]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };

  const handleMicPressIn = async () => {
    setTimerRunning(true);
    setMode(AppMode.voice);
    setScaleAnimation({ transform: "scale(2)" });
    setWidthAnimation({ width: "91%" });
    try {
      console.log("connection checked - audio recording start");
      await audioRecordingStart();
    } catch {}
  };

  const handleMicPressOut = () => {
    setWidthAnimation({ width: "94%" });
    setMode(AppMode.text);
    setScaleAnimation({ transform: "scale(1)" });
    audioRecordingStop();
    setTimerRunning(false);
    setSeconds(0);
    isOnline && dispatch(setMimiIsTyping(true));
    setTimeout(
      () => window.dispatchEvent(new CustomEvent(TwinAppEvent.onScrollToEnd)),
      500
    );
  };

  const handleTextChange = (e: { target: { value: any } }) => {
    dispatch(setTextChat(e.target.value));
    sendMessage(e.target.value);
  };

  return (
    <Paper
      className={`fixed bottom-6 left-0 right-0 z-[1] !bg-transparent !shadow-none lg:mx-HORIZONTAL_MARGIN md:mx-[10%] mx-[5%]`}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyItems: "center",
          WebkitAlignItems: "center",
        }}
      >
        <animated.div style={widthAnimation}>
          <Paper
            style={{
              flex: 1,
              maxHeight: "95vh",
              borderRadius: 15,
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: theme.colors.background,
              color: theme.colors.textPrimary,
            }}
          >
            {mode === AppMode.voice ? (
              <div
                style={{
                  height: 56,
                  borderRadius: 15,
                  backgroundColor: theme.colors.tertiary,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  padding: "0 20px",
                }}
              >
                <div
                  style={{
                    alignSelf: "center",
                    color: "#A1865E",
                    fontSize: 18,
                  }}
                >
                  {timerRunning ? formatTime(seconds) : "00:00"}
                </div>
                <div style={{ margin: "0 20px" }}>
                  <Lottie options={defaultOptions} height={80} width={120} />
                </div>
                <IconButton
                  sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: "1rem",
                    color: "black",
                    "&::placeholder": {
                      color: "black",
                      opacity: 1,
                    },
                    "@media (max-width: 600px)": {
                      fontSize: "1rem",
                      "&::placeholder": {
                        fontSize: "1rem",
                      },
                    },
                    "@media (max-width: 400px)": {
                      fontSize: "0.8rem",
                      "&::placeholder": {
                        fontSize: "0.8rem",
                      },
                    },
                    "@media (max-width: 300px)": {
                      fontSize: "0.7rem",
                      "&::placeholder": {
                        fontSize: "0.7rem",
                      },
                    },
                    "@media (max-width: 200px)": {
                      fontSize: "0.6rem",
                      "&::placeholder": {
                        fontSize: "0.6rem",
                      },
                    },
                  }}
                  onClick={async (e) => {}}
                >
                  <InputAdornment position="end">
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        color: "grey",
                        "@media (max-width: 600px)": {
                          fontSize: "1rem",
                        },
                        "@media (max-width: 400px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (max-width: 300px)": {
                          fontSize: "0.7rem",
                        },
                        "@media (max-width: 200px)": {
                          fontSize: "0.6rem",
                        },
                      }}
                    >
                      Type or speak (press & hold mic button)
                    </Typography>
                  </InputAdornment>
                </IconButton>
              </div>
            ) : (
              <TextField
                id="text-chat"
                autoFocus
                multiline
                sx={{
                  ml: 1,
                  flex: 1,
                  width: "100%",
                  fontSize: "1rem",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: theme.colors.textPrimary,
                  },
                  "@media (max-width: 600px)": {
                    fontSize: "1rem",
                    "&::placeholder": {
                      fontSize: "1rem",
                    },
                  },
                  "@media (max-width: 400px)": {
                    fontSize: "0.8rem",
                    "&::placeholder": {
                      fontSize: "0.8rem",
                    },
                  },
                  "@media (max-width: 300px)": {
                    fontSize: "0.7rem",
                    "&::placeholder": {
                      fontSize: "0.7rem",
                    },
                  },
                  "@media (max-width: 200px)": {
                    fontSize: "0.6rem",
                    "&::placeholder": {
                      fontSize: "0.6rem",
                    },
                  },
                }}
                placeholder="Type or speak (press & hold mic button)"
                onChange={handleTextChange}
                onKeyDown={async (e) => {
                  if (e.key === "Enter") {
                    const target = e.target as HTMLInputElement;
                    e.preventDefault();

                    if (target.value !== "") {
                      sendMessage({ payload: "\n", label: messageLabel });
                      target.value = "";
                    }
                  }
                }}
                onFocus={() => setIsChatBoxFocused(true)}
                onBlur={() => setIsChatBoxFocused(false)}
              />
            )}
          </Paper>
        </animated.div>
        <Box
          sx={{
            right: 0,
            marginLeft: 1,
          }}
        >
          <animated.div style={scaleAnimation}>
            <Paper
              sx={{
                display: "flex",
                borderRadius: "100%",
                backgroundColor: theme.colors.tertiary,
                color: "#ffffff",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                height: "60px",
                width: "60px",
                alignSelf: "flex-end",
                position: "relative",
              }}
              data-testid="chat-mode"
            >
              <IconButton
                onMouseDown={handleMicPressIn}
                onMouseUp={handleMicPressOut}
                sx={{
                  p: "10px",
                  position: "relative",
                  height: "60px",
                  width: "60px",
                }}
                aria-label="chat-mode"
                component="span"
              >
                <MicIcon sx={{ color: "#A1865E", width: 30, height: 30 }} />
                {isOnline && (
                  <PulseCircle
                    style={{ position: "absolute", top: 12, left: 12 }}
                    color={hexToRgba("#A1865E", 0.3)}
                  />
                )}
              </IconButton>
            </Paper>
          </animated.div>

          <Paper
            sx={{
              position: "absolute",
              top: mode === AppMode.voice ? -30 : 0,
            }}
          >
            {showTooltip &&  <Paper
              sx={{
                background: "transparent",
                border: "none",
                position: "absolute",
                transform: "translate(0, -80%)",
                boxShadow: "none",
                "@keyframes overlayAnimation": {
                  "0%": {
                    opacity: 0,
                  },
                  "100%": {
                    opacity: 1,
                  },
                },
                "@keyframes hideAnimation": {
                  "0%": {
                    opacity: 1,
                  },
                  "100%": {
                    opacity: 0,
                  },
                },
                "@keyframes upDownAnimation": {
                  "0%, 100%": {
                    transform: "translate(0, -80%) translateY(0)",
                  },
                  "50%": {
                    transform: "translate(0, -80%) translateY(-20px)",
                  },
                },
                animation: showTooltip
                  ? "overlayAnimation 2s forwards, upDownAnimation 3s infinite"
                  : "hideAnimation 2s forwards",
              }}
            >
              <ArrowDownwardIcon
                sx={{
                  fontSize: 60,
                  color: theme.colors.tertiary,
                }}
              />
              <Box
                sx={{
                  bgcolor: theme.colors.tertiary,
                  padding: "20px",
                  paddingInline: "30px",
                  borderRadius: "20px",
                  position: "absolute",
                  transform: "translate(-22%, -150%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: -2,
                }}
              >
                <Typography
                  sx={{
                    color: theme.colors.textPrimary,
                    fontWeight: "bold",
                    fontSize: "18px",
                    width: "fit-content",
                  }}
                >
                  PUSH
                </Typography>
                <Typography
                  sx={{
                    color: theme.colors.textPrimary,
                    fontWeight: "bold",
                    fontSize: "12px",
                    width: "fit-content",
                  }}
                >
                  &
                </Typography>
                <Typography
                  sx={{
                    color: theme.colors.textPrimary,
                    fontWeight: "bold",
                    fontSize: "18px",
                    width: "fit-content",
                  }}
                >
                  HOLD
                </Typography>
              </Box>
            </Paper>}
          </Paper>
        </Box>
      </Box>
      {mimiIsTyping && (
        <div
          style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
        />
      )}
    </Paper>
  );
};
