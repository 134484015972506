import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { getOS, OS, getBrowser, Browser } from "@twin-shared";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../app/store";
import { messaging, registerServiceWorker } from "../utility/firebase-config";
import { getToken, onMessage } from "firebase/messaging";
import env from "react-dotenv";
import { getBrowserId } from "../utility";
import { useSocketContext } from "./SocketProvider";
import {
  PUSH_NOTIFICATION_TOKEN_KEY,
  PUSH_NOTIFICATION_DEVICE_ID,
  PUSH_NOTIFICATION_TOKEN_ID,
} from "@twin-shared/lib/constants";
import { addClickId, addNotification, removeNotification, updateClickIds } from "../features/notification";
import { clearNotifications, getNotifications } from "../utility/indexedDB";

const { FIREBASE_VAPID_KEY } = env;

interface AppContextProps {
  readonly isChatBoxFocused: boolean;
  authToken: string | null;
  hasPermissions: boolean;
  hasNotificationPermissions: boolean;
  os: OS | null;
  browser: Browser | null;
  requestPermissions: () => void;
  requestNotificationPermission: () => void;
  setIsChatBoxFocused: (val: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  isChatBoxFocused: false,
  authToken: null,
  hasPermissions: false,
  hasNotificationPermissions: false,
  os: null,
  browser: null,
  requestPermissions: () => null,
  requestNotificationPermission: () => null,
  setIsChatBoxFocused: () => null,
});

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { setNotification, isOnline } = useSocketContext();
  const authToken = useSelector((state: RootState) => state.app.authToken);
  const [isChatBoxFocused, setIsChatBoxFocused] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [hasNotificationPermissions, setHasNotificationPermissions] = useState(
    Notification.permission === "granted"
  );
  const notificationList = useSelector((state: RootState) => state.notification.list);
  const notificationClickIds = useSelector((state: RootState) => state.notification.clickIds);
  const [os, setOs] = useState<OS | null>(null);
  const [browser, setBrowser] = useState<Browser | null>(null);
  const dispatch: AppDispatch = useAppDispatch();
  const [showNotificationPermissions, setShowNotificationPermissions] =
    useState(false);

  useEffect(() => {
    setOs(getOS());
    setBrowser(getBrowser());

    // Register Firebase service worker
    if ("serviceWorker" in navigator) {
      registerServiceWorker();
    }

    const onMessageSubscription = onMessage(messaging, (payload) => {
      console.log("Message received: ", payload.messageId);
      dispatch(
        addNotification({ ...payload, id: payload.data.id, markAsRead: false, expand: false })
      );
    });

    navigator.permissions && navigator.permissions
      .query({ name: "microphone" as PermissionName })
      .then(function (permissionStatus) {
        console.log("microphone", permissionStatus.state);
        if (getBrowser() === Browser.Safari) {
          setHasPermissions(true);
        } else {
          setHasPermissions(permissionStatus.state === "granted");
          permissionStatus.onchange = function () {
            console.log("microphone", permissionStatus.state);
            setHasPermissions(this.state === "granted");
          };
        }
      });

    const handleServiceWorkerMessage = (event) => {
      if (event.data && event.data.type === "NOTIFICATION_CLICK") {
        const data = event.data.payload;
        dispatch(addClickId(data.id))
      }
    };

    navigator.serviceWorker?.addEventListener("message", handleServiceWorkerMessage);
    return () => {
      onMessageSubscription && onMessageSubscription();
      navigator.serviceWorker?.removeEventListener("message", handleServiceWorkerMessage);
    }
  }, []);


  useEffect(() => {
    let timeout: any = -1;
    const handleVisibilityChange = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (!document.hidden) {
          getNotifications()
            .then(
              (notifications: any) =>
                new Promise((res) => {
                  notifications.forEach((payload: any) => {
                    const notFound = notificationList.indexOf(item => item.data.id !== payload.data.id) === -1
                    if (notFound) {
                      dispatch(
                        addNotification({ ...payload, id: payload.data.id, markAsRead: false, expand: false })
                      );
                    }
                  });
                  res(notifications);
                })
            )
            .then(() => clearNotifications())
            .then(() => console.log("Data imported successfuly!"));
        }
      }, 1000);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout: any = -1;
    if (localStorage.getItem(PUSH_NOTIFICATION_TOKEN_ID) === "" && authToken) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setNotification({
          fcmToken: localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY),
          deviceId: localStorage.getItem(PUSH_NOTIFICATION_DEVICE_ID),
        });
      }, 2000);
    }

    return () => clearTimeout(timeout);
  }, [isOnline, authToken]);

  useEffect(() => {
    if (
      hasPermissions &&
      !hasNotificationPermissions &&
      !localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY)
    ) {
      setTimeout(() => setShowNotificationPermissions(true), 1000);
    }

    if (
      hasPermissions &&
      hasNotificationPermissions &&
      !localStorage.getItem(PUSH_NOTIFICATION_TOKEN_KEY)
    ) {
      _requestNotificationPermission().then();
    }
  }, [hasNotificationPermissions, hasPermissions]);

  useEffect(() => {
    const ids = [];
    if (notificationClickIds && notificationClickIds.length > 0) {
      notificationClickIds.map(id => {
        if (notificationList.findIndex(a => a.id === id) !== -1) {
          dispatch(removeNotification(id))
          ids.push(id)
        }
      })

      if (ids.length > 0) {
        dispatch(updateClickIds(ids))
      }
    }
  }, [notificationClickIds, notificationList])

  const requestPermissions = async () => setHasPermissions(true);

  const requestNotificationPermission = () =>
    _requestNotificationPermission()
      .then(() => console.log("Notification Permission requested"))
      .catch((err) => console.log(err));

  const _requestNotificationPermission = async () => {
    try {
      const authorizationStatus = await Notification.requestPermission();
      if (authorizationStatus === "granted") {
        setHasNotificationPermissions(true);
        setShowNotificationPermissions(false);
        try {
          const fcmToken = await getToken(messaging, {
            vapidKey: FIREBASE_VAPID_KEY,
          });
          localStorage.setItem(PUSH_NOTIFICATION_TOKEN_KEY, fcmToken);
          localStorage.setItem(PUSH_NOTIFICATION_TOKEN_ID, "");
          localStorage.setItem(PUSH_NOTIFICATION_DEVICE_ID, getBrowserId());
        } catch (err) {
          console.error(err);
          return _requestNotificationPermission();
        }
      }
    } catch (error) {
      console.error("Unable to get permission to notify.", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        authToken,
        hasPermissions,
        hasNotificationPermissions,
        requestPermissions,
        requestNotificationPermission,
        isChatBoxFocused,
        os,
        browser,
        setIsChatBoxFocused,
      }}
    >
      {showNotificationPermissions &&
        localStorage.getItem("disable_alert") !== "true" &&
        Notification.permission === "default" && (
          <div className="flex sm:opacity-90 md:opacity-80 z-50 flex-row  text-right absolute md:right-2 md:top-2 border p-5 bg-white rounded-lg shadow-lg max-w-[23rem]">
            <div className="flex justify-center items-center pr-5">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                className="text-blue-500 w-6 h-6 animate-bounce"
                aria-hidden="true"
                data-pc-el-id="FaBell-14-14"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"></path>
              </svg>
            </div>
            <div className="text-left">
              <h3 className="font-semibold text-lg">Enable Notifications</h3>
              <p className="mb-3">
                Stay updated with the latest updates by enabling notifications.
              </p>
              <button
                className="my-1 mr-2 px-4 py-2 font-medium text-center inline-flex items-center text-white rounded-md border border-blue-600 bg-blue-600 hover:bg-blue-700 transition focus:ring-4 focus:outline-none focus:ring-gray-300"
                onClick={() => {
                  _requestNotificationPermission().then();
                  setShowNotificationPermissions(false);
                }}
              >
                Enable
              </button>

              <button
                className="my-1 mr-2 px-4 py-2 font-medium text-center inline-flex items-center text-white rounded-md border border-slate-600 bg-slate-600 hover:bg-gray-700 transition focus:ring-4 focus:outline-none focus:ring-gray-300"
                onClick={() => {
                  setShowNotificationPermissions(false);
                  localStorage.setItem("disable_alert", "true");
                }}
              >
                Not Now
              </button>
            </div>
            <div className="flex justify-center items-start">
              <button
                className="text-gray-400 hover:text-gray-500"
                aria-label="Close"
                data-pc-el-id="button-23-25"
                onClick={() => setShowNotificationPermissions(false)}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 352 512"
                  className="w-5 h-5"
                  data-pc-el-id="FaTimes-24-24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </button>
            </div>
          </div>
        )}
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("AppContext must be used inside the AppProvider");
  }

  return context;
};

